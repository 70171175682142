import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { marked } from "marked"

const MarkdownTitle = props => {
  const { text, style } = props

  if (_.isEmpty(text)) {
    return null
  }

  const renderer = {
    paragraph(text) {
      return `${text}`
    },
    strong(text) {
      return `<span class= ${
        style !== "textBold" ? "highlight" : "textBold"
      }>${text}</span>`
    }
  }
  marked.use({ renderer })
  return style !== "textBold" ? (
    <span dangerouslySetInnerHTML={{ __html: marked.parse(text) }} />
  ) : (
    <span
      className="spanBold"
      dangerouslySetInnerHTML={{ __html: marked.parse(text) }}
    />
  )
}

MarkdownTitle.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string
}

export default MarkdownTitle
