import { useEffect } from "react"
import { getPageSize } from "./getPageSize"

export const usePageTracking = extraData => {
  let pageTrackTimeout = null

  const clearPageTrackTimeout = () => {
    if (pageTrackTimeout) {
      clearTimeout(pageTrackTimeout)
    }
  }

  const trackPageView = () => {
    if (
      window &&
      window.analytics &&
      window.analytics.page &&
      window.__agicap_page_data
    ) {
      const pageSize = getPageSize()

      const pageData = window.__agicap_page_data
      const pageTitle = document.title

      let trackData = {
        name: document.title,
        path: window.location.pathname,
        referrer: document.referrer,
        search: document.location.search,
        title: pageTitle,
        url: document.URL,
        page_size: pageSize,
        page_perimeter: pageData.page_perimeter,
        page_publication: pageData.page_publication,
        page_feature: pageData.page_feature,
        page_country: pageData.country_code
      }

      if (extraData) {
        trackData = {
          ...extraData,
          ...trackData
        }
      }

      window.analytics.page(pageTitle, trackData)
    } else {
      pageTrackTimeout = setTimeout(trackPageView, 100)
    }
  }

  useEffect(() => {
    trackPageView()
    return () => {
      clearPageTrackTimeout()
    }
  }, [])
}
