import { useEffect } from "react"
import { trackEvent } from "./trackEvent"

const scrollTrackBreakpoints = [25, 50, 75, 100]

export const useScrollTracking = () => {
  let eventTimeout = null
  let scrollPercent = 0

  const getScrollPercent = () => {
    const h = document.documentElement
    const b = document.body
    const st = "scrollTop"
    const sh = "scrollHeight"
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
  }

  const scrollEventHandler = event => {
    const scroll = getScrollPercent()

    let maxScrollPercentage = 0

    scrollTrackBreakpoints.forEach(breakpoint => {
      if (scroll >= breakpoint) {
        maxScrollPercentage = breakpoint
      }
    })

    if (scrollPercent < maxScrollPercentage) {
      scrollPercent = maxScrollPercentage

      invalidateTimeout()
      eventTimeout = setTimeout(() => {
        trackScroll(scrollPercent)
      }, 500)
    }
  }

  const trackScroll = scrollPercent => {
    if (
      window &&
      window.analytics &&
      window.analytics.track &&
      window.__agicap_page_data &&
      scrollPercent > 0
    ) {
      const eventAction = `scroll_${scrollPercent}%`
      const trackingData = {
        category: "interaction",
        label: "{{page_perimeter}}",
        nonInteraction: 1
      }

      trackEvent(eventAction, null, null, trackingData)
    }
  }

  const invalidateTimeout = () => {
    if (eventTimeout) {
      clearTimeout(eventTimeout)
    }
  }

  // add window scroll event handler
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", scrollEventHandler, { passive: true })
    }

    return () => {
      invalidateTimeout()
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", scrollEventHandler)
      }
    }
  }, [])
}
