/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
const PAGE_REGEX_MAPPINGS = {
  feature: "\/[a-z]{2}\/(fonctionnalites|features|funktionen|funcionalidades|functionaliteiten|funzionalita|funktioner)\/.*",
  sector: "\/[a-z]{2}\/(secteur|sector|settore|branche)\/.*",
  pricing: "\/[a-z]{2}\/(pricing|tarifs|preise|precios|tarieven|tariffe|priser)\/.*",
  marketing_resource: "\/[a-z]{2}\/(resources|resourcen|recursos|resources|inscription-cash-academy|cash-academy)\/?$",
  form: "\/[a-z]{2}\/(demo|dimostrazione|contactez-nous|kontakt-aufnehmen|contacto|contact-us|contact|contatto|Kontact)\/?",
  customer_care: "\/[a-z]{2}\/(accompagnement|support|soporte|ondersteuning|accompagnamento|sicherheit|contactez-nous|kontakt-aufnehmen|contacto|contact-us|contact|contatto|Kontact)\/.*"
}

export const getPagePerimeter = (slug, path) => {
  switch (slug) {
    case "home":
      return "home_page"
    case "blog":
      return "blog_home"
    case "article":
      return "article"
  }

  const keys = Object.keys(PAGE_REGEX_MAPPINGS)
  for (let i = 0; i < keys.length; i++) {
    const regex = new RegExp(PAGE_REGEX_MAPPINGS[keys[i]])
    if (regex.test(path)) {
      return keys[i]
    }
  }

  return "other"
}
