const _ = require("lodash")
const { getLocaleLanguageCode } = require("./utilities/utilities")

const Keys = {
  CLIENTS_READ_CASE: "CLIENTS_READ_CASE",
  CLIENT_ACTIVITY_AREA: "CLIENT_ACTIVITY_AREA",
  CLIENT_USE_CASE: "CLIENT_USE_CASE",
  CLIENT_SIZE: "CLIENT_SIZE",
  CLIENT_LOCATION: "CLIENT_LOCATION",
  CLIENT_CASH_ISSUE: "CLIENT_CASH_ISSUE",
  CLIENT_ACTIVITY_AREA_ALL: "CLIENT_ACTIVITY_AREA_ALL",
  CLIENT_USE_CASE_ALL: "CLIENT_USE_CASE_ALL",
  CLIENT_SIZE_ALL: "CLIENT_SIZE_ALL",
  CLIENT_PRODUCT_ALL: "CLIENT_PRODUCT_ALL",
  CLIENT_ROLE_ALL: "CLIENT_ROLE_ALL",
  RESOURCE_ACTIVITY_AREA_ALL: "RESOURCE_ACTIVITY_AREA_ALL",
  RESOURCE_FORMAT_ALL: "RESOURCE_FORMAT_ALL",
  PARTNER_ACTIVITY_AREA_ALL: "PARTNER_ACTIVITY_AREA_ALL",
  RELATED_ARTICLES_SECTION_TITLE: "RELATED_ARTICLES_SECTION_TITLE",
  CATEGORY_PAGE_PREFIX: "CATEGORY_PAGE_PREFIX",
  HOME: "HOME",
  ARTICLE_SLUG_PREFIX: "ARTICLE_SLUG_PREFIX",
  GLOSSARY_SLUG_PREFIX: "GLOSSARY_SLUG_PREFIX",
  BLOG_TITLE: "BLOG_TITLE",
  BLOG_SUBTITLE: "BLOG_SUBTITLE",
  UPDATED_ON: "UPDATED_ON",
  SUBSCRIBE_TO_NEWSLETTER: "SUBSCRIBE_TO_NEWSLETTER",
  SUBSCRIPTION_BLOCK_TEXT: "SUBSCRIPTION_BLOCK_TEXT",
  READING_TIME: "READING_TIME",
  TABLE_OF_CONTENT: "TABLE_OF_CONTENT",
  CATEGORIES: "CATEGORIES",
  CATEGORIES_SUBTITLE: "CATEGORIES_SUBTITLE",
  LATEST_ARTICLES: "LATEST_ARTICLES"
}

const translations = {
  fr: {
    [Keys.CLIENTS_READ_CASE]: "Lire le témoignage",
    [Keys.CLIENT_ACTIVITY_AREA]: "Secteur d’activité",
    [Keys.CLIENT_USE_CASE]: "Cas d'usage",
    [Keys.CLIENT_SIZE]: "Taille",
    [Keys.CLIENT_LOCATION]: "Où",
    [Keys.CLIENT_CASH_ISSUE]: "Enjeu de trésorerie",
    [Keys.CLIENT_USE_CASE_ALL]: "Cas d´usage",
    [Keys.CLIENT_ACTIVITY_AREA_ALL]: "Secteur d´activité",
    [Keys.CLIENT_ROLE_ALL]: "Rôle",
    [Keys.CLIENT_SIZE_ALL]: "Taille",
    [Keys.CLIENT_PRODUCT_ALL]: "Produit",
    [Keys.RESOURCE_ACTIVITY_AREA_ALL]: "Secteur d´activité",
    [Keys.RESOURCE_FORMAT_ALL]: "Format",
    [Keys.PARTNER_ACTIVITY_AREA_ALL]: "Secteur d´activité",
    [Keys.RELATED_ARTICLES_SECTION_TITLE]: "Vous aimerez aussi",
    [Keys.CATEGORY_PAGE_PREFIX]: "categorie",
    [Keys.HOME]: "Accueil",
    [Keys.ARTICLE_SLUG_PREFIX]: "article",
    [Keys.GLOSSARY_SLUG_PREFIX]: "glossaire",
    [Keys.BLOG_TITLE]: "Blog",
    [Keys.BLOG_SUBTITLE]:
      "Les meilleures ressources pour piloter votre entreprise sainement.",
    [Keys.UPDATED_ON]: "mis à jour le",
    [Keys.SUBSCRIBE_TO_NEWSLETTER]: "S'inscrire à notre newsletter",
    [Keys.SUBSCRIPTION_BLOCK_TEXT]:
      "Inscrivez-vous à notre newsletter et recevez gratuitement nos articles et ressources sur la gestion de la trésorerie.",
    [Keys.READING_TIME]: "Temps de lecture",
    [Keys.TABLE_OF_CONTENT]: "Table des matières",
    [Keys.CATEGORIES]: "Catégories",
    [Keys.CATEGORIES_SUBTITLE]:
      "Toutes nos ressources pour apprendre à gérer votre trésorerie.",
    [Keys.LATEST_ARTICLES]: "Derniers articles"
  },
  de: {
    [Keys.CLIENTS_READ_CASE]: "Lesen Sie den Fall",
    [Keys.CLIENT_ACTIVITY_AREA]: "Tätigkeitsbereich",
    [Keys.CLIENT_USE_CASE]: "Anwendungsfälle",
    [Keys.CLIENT_SIZE]: "Mitarbeiteranzahl",
    [Keys.CLIENT_LOCATION]: "Ort",
    [Keys.CLIENT_CASH_ISSUE]: "Aktuelle Herausforderung",
    [Keys.CLIENT_USE_CASE_ALL]: "Anwendungsfall",
    [Keys.CLIENT_ACTIVITY_AREA_ALL]: "Tätigkeitsbereich",
    [Keys.CLIENT_ROLE_ALL]: "Jobtitel",
    [Keys.CLIENT_SIZE_ALL]: "Unternehmensgröße",
    [Keys.CLIENT_PRODUCT_ALL]: "Produkt",
    [Keys.RESOURCE_ACTIVITY_AREA_ALL]: "Geschäftssektor",
    [Keys.RESOURCE_FORMAT_ALL]: "Format",
    [Keys.PARTNER_ACTIVITY_AREA_ALL]: "Geschäftssektor",
    [Keys.RELATED_ARTICLES_SECTION_TITLE]: "Das wird Ihnen auch gefallen",
    [Keys.CATEGORY_PAGE_PREFIX]: "kategorie",
    [Keys.HOME]: "Home",
    [Keys.ARTICLE_SLUG_PREFIX]: "artikel",
    [Keys.GLOSSARY_SLUG_PREFIX]: "glossar",
    [Keys.BLOG_TITLE]: "Blog",
    [Keys.BLOG_SUBTITLE]:
      "Die besten Ressourcen für eine solide Führung Ihres Unternehmens.",
    [Keys.UPDATED_ON]: "aktualisiert am",
    [Keys.SUBSCRIBE_TO_NEWSLETTER]:
      "Melden Sie sich für unseren Newsletter an.",
    [Keys.SUBSCRIPTION_BLOCK_TEXT]:
      "Melden Sie sich für unseren Newsletter an und erhalten Sie kostenlos unsere Artikel und Ressourcen zum Thema Cash Management.",
    [Keys.READING_TIME]: "Lesezeit",
    [Keys.TABLE_OF_CONTENT]: "Inhalt",
    [Keys.CATEGORIES]: "Kategorien",
    [Keys.CATEGORIES_SUBTITLE]:
      "Stöbern Sie in unseren Ressourcen, um zu erfahren, wie Sie Ihren Cashflow verwalten können",
    [Keys.LATEST_ARTICLES]: "Neueste Artikel"
  },
  es: {
    [Keys.CLIENTS_READ_CASE]: "Leer el caso",
    [Keys.CLIENT_ACTIVITY_AREA]: "Sector de actividad",
    [Keys.CLIENT_USE_CASE]: "Casos de Éxito",
    [Keys.CLIENT_SIZE]: "Tamaño",
    [Keys.CLIENT_LOCATION]: "Dónde",
    [Keys.CLIENT_CASH_ISSUE]: "Retos de tesorería",
    [Keys.CLIENT_USE_CASE_ALL]: "Casos de Éxito",
    [Keys.CLIENT_ACTIVITY_AREA_ALL]: "Sector de actividad",
    [Keys.CLIENT_ROLE_ALL]: "Función",
    [Keys.CLIENT_SIZE_ALL]: "Tamaño",
    [Keys.RESOURCE_ACTIVITY_AREA_ALL]: "Sector de actividad",
    [Keys.RESOURCE_FORMAT_ALL]: "Formato",
    [Keys.PARTNER_ACTIVITY_AREA_ALL]: "Sector de actividad",
    [Keys.RELATED_ARTICLES_SECTION_TITLE]: "También le gustara",
    [Keys.CATEGORY_PAGE_PREFIX]: "categoria",
    [Keys.HOME]: "Home",
    [Keys.ARTICLE_SLUG_PREFIX]: "articulo",
    [Keys.GLOSSARY_SLUG_PREFIX]: "glosario",
    [Keys.BLOG_TITLE]: "Blog",
    [Keys.BLOG_SUBTITLE]:
      "Los mejores recursos para dirigir tu negocio con solvencia.",
    [Keys.UPDATED_ON]: "actualizado en",
    [Keys.SUBSCRIBE_TO_NEWSLETTER]: "Suscríbete a nuestro boletín de noticias",
    [Keys.SUBSCRIPTION_BLOCK_TEXT]:
      "Suscríbete a nuestro boletín de noticias y recibe gratuitamente nuestros artículos y recursos sobre gestión de tesorería.",
    [Keys.READING_TIME]: "Tiempo de lectura",
    [Keys.TABLE_OF_CONTENT]: "Índice",
    [Keys.CATEGORIES]: "Categorías",
    [Keys.CATEGORIES_SUBTITLE]:
      "Todos nuestros recursos para aprender a gestionar tu tesorería.",
    [Keys.LATEST_ARTICLES]: "Últimos artículos"
  },
  en: {
    [Keys.CLIENTS_READ_CASE]: "Read the case",
    [Keys.CLIENT_ACTIVITY_AREA]: "Business sector",
    [Keys.CLIENT_USE_CASE]: "Use case",
    [Keys.CLIENT_SIZE]: "Size",
    [Keys.CLIENT_LOCATION]: "Where",
    [Keys.CLIENT_CASH_ISSUE]: "Cashflow challenges",
    [Keys.CLIENT_USE_CASE_ALL]: "Use case",
    [Keys.CLIENT_ACTIVITY_AREA_ALL]: "Business sector",
    [Keys.CLIENT_ROLE_ALL]: "Role",
    [Keys.CLIENT_SIZE_ALL]: "Company Size",
    [Keys.CLIENT_PRODUCT_ALL]: "Product",
    [Keys.RESOURCE_ACTIVITY_AREA_ALL]: "Business sector",
    [Keys.RESOURCE_FORMAT_ALL]: "Format",
    [Keys.PARTNER_ACTIVITY_AREA_ALL]: "Business sector",
    [Keys.RELATED_ARTICLES_SECTION_TITLE]: "You may also like",
    [Keys.CATEGORY_PAGE_PREFIX]: "category",
    [Keys.HOME]: "Home",
    [Keys.ARTICLE_SLUG_PREFIX]: "article",
    [Keys.GLOSSARY_SLUG_PREFIX]: "glossary",
    [Keys.BLOG_TITLE]: "Blog",
    [Keys.BLOG_SUBTITLE]: "The best resources to run your business soundly.",
    [Keys.UPDATED_ON]: "updated on",
    [Keys.SUBSCRIBE_TO_NEWSLETTER]: "Subscribe to our newsletter",
    [Keys.SUBSCRIPTION_BLOCK_TEXT]:
      "Sign up for our newsletter and receive our free cash management articles and resources.",
    [Keys.READING_TIME]: "Reading time",
    [Keys.TABLE_OF_CONTENT]: "Table of content",
    [Keys.CATEGORIES]: "Categories",
    [Keys.CATEGORIES_SUBTITLE]:
      "Browse our resources to learn how to manage your cash flow",
    [Keys.LATEST_ARTICLES]: "Latest Articles"
  },
  "en-us": {
    [Keys.CLIENTS_READ_CASE]: "Read the case",
    [Keys.CLIENT_ACTIVITY_AREA]: "Business sector",
    [Keys.CLIENT_USE_CASE]: "Use case",
    [Keys.CLIENT_SIZE]: "Size",
    [Keys.CLIENT_LOCATION]: "Where",
    [Keys.CLIENT_CASH_ISSUE]: "Cashflow challenges",
    [Keys.CLIENT_USE_CASE_ALL]: "Use case",
    [Keys.CLIENT_ACTIVITY_AREA_ALL]: "Business sector",
    [Keys.CLIENT_ROLE_ALL]: "Role",
    [Keys.CLIENT_SIZE_ALL]: "Company Size",
    [Keys.CLIENT_PRODUCT_ALL]: "Product",
    [Keys.RESOURCE_ACTIVITY_AREA_ALL]: "Business sector",
    [Keys.RESOURCE_FORMAT_ALL]: "Format",
    [Keys.PARTNER_ACTIVITY_AREA_ALL]: "Business sector",
    [Keys.RELATED_ARTICLES_SECTION_TITLE]: "You may also like",
    [Keys.CATEGORY_PAGE_PREFIX]: "category",
    [Keys.HOME]: "Home",
    [Keys.ARTICLE_SLUG_PREFIX]: "article",
    [Keys.GLOSSARY_SLUG_PREFIX]: "glossary",
    [Keys.BLOG_TITLE]: "Blog",
    [Keys.BLOG_SUBTITLE]: "The best resources to run your business soundly.",
    [Keys.UPDATED_ON]: "updated on",
    [Keys.SUBSCRIBE_TO_NEWSLETTER]: "Subscribe to our newsletter",
    [Keys.SUBSCRIPTION_BLOCK_TEXT]:
      "Sign up for our newsletter and receive our free cash management articles and resources.",
    [Keys.READING_TIME]: "Reading time",
    [Keys.TABLE_OF_CONTENT]: "Table of content",
    [Keys.CATEGORIES]: "Categories",
    [Keys.CATEGORIES_SUBTITLE]:
      "Browse our resources to learn how to manage your cash flow",
    [Keys.LATEST_ARTICLES]: "Latest Articles"
  },
  nl: {
    [Keys.CLIENTS_READ_CASE]: "Bekijk de video",
    [Keys.CLIENT_ACTIVITY_AREA]: "Bedrijfssector",
    [Keys.CLIENT_USE_CASE]: "Gebruikssituaties",
    [Keys.CLIENT_SIZE]: "Bedrijfsgrootte",
    [Keys.CLIENT_LOCATION]: "Waar",
    [Keys.CLIENT_CASH_ISSUE]: "Cashflow uitdagingen",
    [Keys.CLIENT_USE_CASE_ALL]: "Gebruikssituaties",
    [Keys.CLIENT_ACTIVITY_AREA_ALL]: "Bedrijfssector",
    [Keys.CLIENT_ROLE_ALL]: "Rol",
    [Keys.CLIENT_SIZE_ALL]: "Bedrijfsgrootte",
    [Keys.RESOURCE_ACTIVITY_AREA_ALL]: "Bedrijfssector",
    [Keys.RESOURCE_FORMAT_ALL]: "Formaat",
    [Keys.PARTNER_ACTIVITY_AREA_ALL]: "Bedrijfssector",
    [Keys.RELATED_ARTICLES_SECTION_TITLE]: "U zult ook graag",
    [Keys.CATEGORY_PAGE_PREFIX]: "categorie",
    [Keys.HOME]: "Home",
    [Keys.ARTICLE_SLUG_PREFIX]: "artikel",
    [Keys.BLOG_TITLE]: "Blog",
    [Keys.BLOG_SUBTITLE]:
      "Artikelen die je helpen met de beste cashflow insights.",
    [Keys.UPDATED_ON]: "bijgewerkt op",
    [Keys.SUBSCRIBE_TO_NEWSLETTER]: "Schrijf je in voor onze nieuwsbrief",
    [Keys.SUBSCRIPTION_BLOCK_TEXT]:
      "Schrijf je in voor de nieuwsbrief en ontvang artikelen, e-books en whitepapers gratis. Ontdek alles wat je wilt weten over cashflow management",
    [Keys.READING_TIME]: "Leestijd",
    [Keys.TABLE_OF_CONTENT]: "Inhoudsopgave",
    [Keys.CATEGORIES]: "Categorieën",
    [Keys.CATEGORIES_SUBTITLE]:
      "Scroll door onze kennisbank en ontdek hoe je grip krijgt op je cashflow",
    [Keys.LATEST_ARTICLES]: "Nieuwste artikelen"
  },
  it: {
    [Keys.CLIENTS_READ_CASE]: "Scopri la testimonianza",
    [Keys.CLIENT_ACTIVITY_AREA]: "Settore di attività",
    [Keys.CLIENT_USE_CASE]: "Casi d'uso",
    [Keys.CLIENT_SIZE]: "Dimensione",
    [Keys.CLIENT_LOCATION]: "Dove",
    [Keys.CLIENT_CASH_ISSUE]: "Problema di cashflow",
    [Keys.CLIENT_USE_CASE_ALL]: "Casi d'uso",
    [Keys.CLIENT_ACTIVITY_AREA_ALL]: "Settore di attività",
    [Keys.CLIENT_ROLE_ALL]: "Ruolo",
    [Keys.CLIENT_SIZE_ALL]: "Dimensione",
    [Keys.CLIENT_PRODUCT_ALL]: "Prodotto",
    [Keys.RESOURCE_ACTIVITY_AREA_ALL]: "Settore di attività",
    [Keys.RESOURCE_FORMAT_ALL]: "Formato",
    [Keys.PARTNER_ACTIVITY_AREA_ALL]: "Settore di attività",
    [Keys.RELATED_ARTICLES_SECTION_TITLE]: "Potrebbe interessarti anche",
    [Keys.CATEGORY_PAGE_PREFIX]: "categorie",
    [Keys.HOME]: "Casa",
    [Keys.ARTICLE_SLUG_PREFIX]: "articolo",
    [Keys.GLOSSARY_SLUG_PREFIX]: "glossario",
    [Keys.BLOG_TITLE]: "Blog",
    [Keys.BLOG_SUBTITLE]:
      "Le migliori risorse per gestire il tuo business in modo sano.",
    [Keys.UPDATED_ON]: "aggiornato il",
    [Keys.SUBSCRIBE_TO_NEWSLETTER]: "Iscriviti alla nostra newsletter",
    [Keys.SUBSCRIPTION_BLOCK_TEXT]:
      "Iscriviti alla nostra newsletter e ricevi gratuitamente i nostri articoli e risorse per la gestione della liquidità.",
    [Keys.READING_TIME]: "Tempo di lettura",
    [Keys.TABLE_OF_CONTENT]: "Tabella dei contenuti",
    [Keys.CATEGORIES]: "Categorie",
    [Keys.CATEGORIES_SUBTITLE]:
      "Consulta le nostre risorse per imparare a gestire il tuo flusso di cassa",
    [Keys.LATEST_ARTICLES]: "Ultimi articoli"
  },
  da: {
    [Keys.CLIENTS_READ_CASE]: "Seneste artikler",
    [Keys.CLIENT_ACTIVITY_AREA]: "Näringsliv",
    [Keys.CLIENT_USE_CASE]: "Användningsfall",
    [Keys.CLIENT_SIZE]: "Storlek",
    [Keys.CLIENT_LOCATION]: "Var",
    [Keys.CLIENT_CASH_ISSUE]: "Utmaningar för kassaflödet",
    [Keys.CLIENT_USE_CASE_ALL]: "Storlek",
    [Keys.CLIENT_ACTIVITY_AREA_ALL]: "Näringsliv",
    [Keys.CLIENT_ROLE_ALL]: "Roll",
    [Keys.CLIENT_SIZE_ALL]: "Storlek",
    [Keys.RESOURCE_ACTIVITY_AREA_ALL]: "Näringsliv",
    [Keys.RESOURCE_FORMAT_ALL]: "Format",
    [Keys.PARTNER_ACTIVITY_AREA_ALL]: "Näringsliv",
    [Keys.RELATED_ARTICLES_SECTION_TITLE]: "Du kanske också gillar",
    [Keys.CATEGORY_PAGE_PREFIX]: "kategori",
    [Keys.HOME]: "Hem",
    [Keys.ARTICLE_SLUG_PREFIX]: "artikel",
    [Keys.BLOG_TITLE]: "Blog",
    [Keys.BLOG_SUBTITLE]:
      "De bedste ressourcer til at drive din virksomhed sundt.",
    [Keys.UPDATED_ON]: "opdateret den",
    [Keys.SUBSCRIBE_TO_NEWSLETTER]: "Tilmeld dig vores nyhedsbrev",
    [Keys.SUBSCRIPTION_BLOCK_TEXT]:
      "Tilmeld dig vores nyhedsbrev og modtag vores gratis artikler og ressourcer om likviditetsstyring.",
    [Keys.READING_TIME]: "Reading time",
    [Keys.TABLE_OF_CONTENT]: "Indholdsfortegnelse",
    [Keys.CATEGORIES]: "Kategorier",
    [Keys.CATEGORIES_SUBTITLE]:
      "Se vores ressourcer for at lære, hvordan du styrer dit cash flow",
    [Keys.LATEST_ARTICLES]: "Latest Articles"
  }
}

module.exports.KEYS = Keys

module.exports.translate = function (key, locale) {
  const languageCode = getLocaleLanguageCode(locale)
  return _.get(translations, [languageCode, key], "")
}
