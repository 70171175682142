import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"

import styles from "./Breadcrumb.module.scss"
import { stringReplace } from "../../utilities/utilities"

import _ from "lodash"

const Breadcrumb = props => {
  const { links } = props

  const stringReplacements = {
    year: new Date().getFullYear()
  }

  const renderLink = link => {
    if (!link.url) {
      return (
        <span
          itemScope
          itemType="http://schema.org/WebPage"
          itemProp="item"
          itemID={link.id}
        >
          {stringReplace(_.get(link, "title", ""), stringReplacements)}
        </span>
      )
    }

    return (
      <a
        itemScope
        itemType="http://schema.org/WebPage"
        itemProp="item"
        itemID={link.id}
        href={link.url}
      >
        {link.title}
      </a>
    )
  }

  const listClasses = []

  if (props.toggleIconDirectionOnSmallDevices) {
    listClasses.push(styles.togglesIconDirection)
  }

  return (
    <div className={styles.breadcrumb}>
      <Container>
        <ul
          className={listClasses.join(" ")}
          itemScope
          itemType="http://schema.org/BreadcrumbList"
        >
          {links.map((link, index) => {
            const linkClasses = []
            if (link.hideOnSmallDevices) {
              linkClasses.push("d-none")
              linkClasses.push(`d-sm-inline`)
            }
            return (
              <li
                key={index}
                itemScope
                itemProp="itemListElement"
                itemType="http://schema.org/ListItem"
                className={linkClasses.join(" ")}
              >
                <span itemProp="name">{renderLink(link)}</span>
                <meta itemProp="position" content={index + 1} />
              </li>
            )
          })}
        </ul>
      </Container>
    </div>
  )
}

Breadcrumb.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      hideOnSmallDevices: PropTypes.bool
    })
  ),
  toggleIconDirectionOnSmallDevices: PropTypes.bool
}

export default Breadcrumb
