import React from "react"
import style from "./GlossaryGrid.module.scss"
import { Container, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import { getGlossaryPath } from "../../utilities/blogUtilities"

const GlossaryGrid = props => {
  const { articles, locale, defaultLocale } = props

  const sortedArray = []

  const compare = (a, b) => {
    if (
      a.listingTitleGlossaryOnly !== null &&
      b.listingTitleGlossaryOnly !== null
    ) {
      return a.listingTitleGlossaryOnly[0].toLowerCase() <
        b.listingTitleGlossaryOnly[0].toLowerCase()
        ? -1
        : 1
    }
  }
  articles
    .slice()
    .sort(compare)
    .forEach(data => {
      sortedArray.push(data)
    })

  const sortedLetterObject = sortedArray.reduce((r, e) => {
    // get first letter of name of current element
    const alphabet =
      e.listingTitleGlossaryOnly !== null && e.listingTitleGlossaryOnly[0]

    // if there is no property in accumulator with this letter create it
    if (!r[alphabet]) r[alphabet] = { alphabet, record: [e] }
    // if there is push current element to children array for that letter
    else r[alphabet].record.push(e)

    // return accumulator
    return r
  }, {})
  const sortedArrayOnLetterBasis = Object.values(sortedLetterObject)

  return (
    <Container>
      <div className={style.subheading__wrapper}>
        {sortedArrayOnLetterBasis.map(article => {
          return (
            <React.Fragment key={article.alphabet}>
              <GlossaryItem
                article={article}
                locale={locale}
                defaultLocale={defaultLocale}
              />
            </React.Fragment>
          )
        })}
      </div>
    </Container>
  )
}

export default GlossaryGrid

GlossaryGrid.propTypes = {
  articles: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired
}

const GlossaryItem = props => {
  const { article, locale, defaultLocale } = props

  // const path = getGlossaryPath(article, locale, defaultLocale)

  return (
    <>
      <Row className={style.gridrow}>
        <div className={style.letterhead}>
          <h2>{article.alphabet && article.alphabet.toUpperCase()}</h2>
        </div>
        {article.record.map(data => {
          return (
            <div key={data.id}>
              {data.listingTitleGlossaryOnly && (
                <a
                  href={getGlossaryPath(data, locale, defaultLocale)}
                  className={style.atag}
                >
                  <div className={style.subheadings}>
                    {data.listingTitleGlossaryOnly} <span>{" >  "}</span>
                  </div>
                </a>
              )}
            </div>
          )
        })}
      </Row>
    </>
  )
}

GlossaryItem.propTypes = {
  article: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired
}
