import isBrowser from "../utilities/isBrowser"

export const getPageSize = () => {
  if (isBrowser()) {
    const html = document.querySelector("html")
    return html.outerHTML.length
  }

  return 0
}
