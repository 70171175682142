import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import {
  doesTagsContainNoIndex,
  getHTMLLang,
  getHrefLangTagProps,
  getLocaleLanguageCode,
  localizedPath,
  stringReplace
} from "../../utilities/utilities"
import Layout from "../../components/Layout/Layout"
import Template from "../../components/Template/Template"
import { renderTags } from "../../utilities/renderTags"
import { Container } from "react-bootstrap"
import t from "../../translations"
import SEO from "../../components/seo"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"

import styles from "./GlossaryCategory.module.scss"
import getPageData from "../../analytics/getPageData"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"
import getValueOrDefault from "../../analytics/getValueOrDefault"
import GlossaryGrid from "../../components/GlossaryGrid/GlossaryGrid"
import MarkdownTitle from "../../components/MarkdownTitle"

export const query = graphql`
  query ($pageId: String!, $categoryId: String!, $locale: String!) {
    placeholder: file(
      relativePath: { eq: "placeholder-missingblogimage.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 260, aspectRatio: 1.3, layout: CONSTRAINED)
      }
    }
    contentfulPage(id: { eq: $pageId }) {
      ...pageFields
    }
    site {
      siteMetadata {
        defaultLocale
        siteUrl
      }
    }
    contentfulBlogPostCategory(id: { eq: $categoryId }) {
      id
      styleCategoryBlogPost
      slug
      name
      analyticalName
      subtitle
      metaTitle
      metaDescription
      segmentUpdatedAt: updatedAt(formatString: "yyyyMMDD")
      topContent {
        childMarkdownRemark {
          html
        }
      }
      footerContent {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [datePublished], order: [ASC] }
      filter: {
        node_locale: { eq: $locale }
        datePublished: { ne: null }
        category: { id: { eq: $categoryId } }
      }
    ) {
      nodes {
        id
        slug
        dateCreated(formatString: "dd MMM yyyy", locale: $locale)
        datePublished(formatString: "dd MMM yyyy", locale: $locale)
        title
        listingTitleGlossaryOnly
        category {
          id
          slug
          name
        }
        thumbnail: mainImage {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            width: 260
            aspectRatio: 1.3
            resizingBehavior: FILL
          )
        }
      }
    }
  }
`

const GlossaryCategory = props => {
  useScrollTracking()

  const { localeLinks, locale, defaultLocale } = props.pageContext
  const articles = props.data.allContentfulBlogPost.nodes

  const page = props.data.contentfulPage
  const category = props.data.contentfulBlogPostCategory
  // style: blogcategory style [glossary | blogpost]
  const style = props.data.contentfulBlogPostCategory.styleCategoryBlogPost

  const getTitle = props.data.allContentfulBlogPost.nodes

  usePageTracking({
    article_category: getValueOrDefault(category.analyticalName, category.slug)
  })

  const breadcrumb = [
    {
      id: localizedPath("", null, locale, defaultLocale),
      title: t.translate(t.KEYS.HOME, locale),
      url: localizedPath("", null, locale, defaultLocale)
    },
    {
      id: localizedPath("blog", null, locale, defaultLocale),
      title: "Blog",
      url: localizedPath("blog", null, locale, defaultLocale)
    },
    {
      id: props.pageContext.pageUrl,
      title: category.name,
      url: null
    }
  ]

  const renderHead = () => {
    return (
      <Container className="text-center my-5">
        <h1 className="text-primary">
          <MarkdownTitle text={category.name} />
        </h1>
        <p className="text-primary" style={{ fontSize: "1.5rem" }}>
          {category.subtitle}
        </p>
      </Container>
    )
  }

  const renderSection1 = () => {
    if (!category.topContent) {
      return null
    }

    return (
      <Container className="my-5">
        <div
          dangerouslySetInnerHTML={{
            __html: category.topContent.childMarkdownRemark.html
          }}
        />
      </Container>
    )
  }

  const renderSection2 = () => {
    if (!category.footerContent) {
      return null
    }

    return (
      <Container>
        <div
          dangerouslySetInnerHTML={{
            __html: category.footerContent.childMarkdownRemark.html
          }}
        />
      </Container>
    )
  }

  const pageStartScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyStartScripts
  )
  const pageEndScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyEndScripts
  )
  const hasFixedMenu = _.defaultTo(_.get(page, "fixedMenu"), true)

  const htmlLang = getHTMLLang(locale)
  const title = stringReplace(_.get(category, "metaTitle", ""))
  const metaDescription = stringReplace(_.get(category, "metaDescription", ""))
  const pageUrl = `${props.data.site.siteMetadata.siteUrl}${_.get(
    props,
    "pageContext.pageUrl",
    ""
  )}`
  const languageCode = getLocaleLanguageCode(locale)

  const pageData = getPageData(
    locale,
    {
      pagePerimeter: _.get(page, "pagePerimeter", ""),
      pagePublication: category.segmentUpdatedAt,
      pageFeatureSector: _.get(page, "pageFeatureSector", "")
    },
    page.slug,
    props.path
  )
  const doesPageContainsNoIndexTag =
    doesTagsContainNoIndex(page.extraTags) ||
    (props.data.contentfulPage.template &&
      props.data.contentfulPage.template.headTags &&
      doesTagsContainNoIndex(props.data.contentfulPage.template.headTags))

  function renderHrefLangTags() {
    return getHrefLangTagProps(
      localeLinks,
      props.data.site.siteMetadata.siteUrl
    ).map(tagProps => {
      return <link {...tagProps} key={tagProps.key} />
    })
  }

  return (
    <Layout locale={locale} defaultLocale={props.pageContext.defaultLocale}>
      <SEO
        description={metaDescription}
        lang={htmlLang}
        title={title}
        url={pageUrl}
        includeSelfCanonical={!doesPageContainsNoIndexTag}
      />
      <Helmet>
        <script type="text/javascript">{`${pageData}`}</script>
        {renderTags(
          locale,
          props.data.site.siteMetadata.defaultLocale,
          page.extraTags
        )}
        {props.data.contentfulPage.template &&
          props.data.contentfulPage.template.headTags &&
          renderTags(
            locale,
            props.pageContext.defaultLocale,
            props.data.contentfulPage.template.headTags
          )}
        <link rel="alternate" href={pageUrl} hrefLang={languageCode} />
        {renderHrefLangTags()}
      </Helmet>
      <Template
        {...page.template}
        isMenuFixed={hasFixedMenu}
        localeLinks={localeLinks}
        locale={props.pageContext.locale}
        defaultLocale={props.pageContext.defaultLocale}
      >
        {pageStartScriptTags}
        <div className={styles.category}>
          <Breadcrumb links={breadcrumb} />
          {renderHead()}
          {renderSection1()}
          {articles.length > 0 && style === "glossary" && (
            <GlossaryGrid
              title={t.translate(t.KEYS.LATEST_ARTICLES, locale)}
              articles={articles}
              locale={locale}
              defaultLocale={defaultLocale}
              getTitle={getTitle[0].title}
            />
          )}

          {renderSection2()}
        </div>
        {pageEndScriptTags}
      </Template>
    </Layout>
  )
}

GlossaryCategory.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export default GlossaryCategory
